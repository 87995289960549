import React from 'react'
import { Form } from 'react-final-form'

import { OrderCommentForm } from 'forms'
import { FormDataType, FieldName, formDataInitial } from '../forms/OrderCommentForm/OrderCommentForm'

const OrderCommentAction: React.FC = () => {
    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            initialValues={{
                ...formDataInitial,
                comment: 'Engele Sarmite: Групповой заказ Inese Kovalova ID 001-447707 с доставкой на её адрес.', // FIXME
            }}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.comment]) {
                    errors[FieldName.comment] = true
                }

                return errors
            }}
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <OrderCommentForm
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default OrderCommentAction
