import React, {
    useState,
    useMemo,
    useRef,
    useEffect,
    createElement,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { FormDataType as ReportsPaymentFormDataType, FieldName } from 'forms/ReportsPaymentForm/ReportsPaymentForm'
import { FormDataType as PresetFormDataType } from 'forms/ReportsPaymentPresetForm/ReportsPaymentPresetForm'
import { TableConfiguration } from 'containers/Reports/components/ReportsTableView/ReportsTableView'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, Block } from 'layout'
import { useFetchCurrencies } from 'containers/App/hooks'
import {
    ReportsPresets,
    ReportsTableView,
    ReportsChartView,
    ReportsBarView,
} from 'containers/Reports/components'
import {
    PageHeader,
    CustomSelect,
    Button,
    SvgResource,
    Tooltip,
} from 'components'
import { ReportsPaymentAction, ReportsPaymentPresetAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { dateTimeFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportsPayment.module.css'

enum ReportView {
    table = 'table',
    chartLine = 'chartLine',
    chartBar = 'chartBar',
}

const reportActionIcon: Record<ReportView, string> = {
    [ReportView.table]: 'table_svg',
    [ReportView.chartLine]: 'chart_line_svg',
    [ReportView.chartBar]: 'chart_bar_svg',
}

const randomInteger = (min: number, max: number) => {
    const rand = min + Math.random() * (max + 1 - min)
    return Math.floor(rand)
}

const createTableColValue = (key: string, title: string, value: string) => {
    return createElement('div', { className: style.tableCol, key }, [
        createElement('div', { className: style.tableColTitle, key: `${title}-${value}-1` }, title),
        createElement('div', { className: style.tableColValue, key: `${title}-${value}-2` }, value),
    ])
}

const ReportsPayment: React.FC = () => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const reportsRef = useRef<HTMLDivElement>(null)

    const [reportsData, setReportsData] = useState<{
        date: Date
        cash: number
        terminal: number
        getaway: number
        bank: number
        total: number
        cv: number
    }[]>()
    const [reportsParams, setReportsParams] = useState<ReportsPaymentFormDataType>()
    const [activeReportView, setActiveReportView] = useState<ReportView>(ReportView.table)

    const [presetsData, setPresetsData] = useState([
        { id: 1, name: 'Таблетосы' },
        { id: 2, name: 'Чай кофе потанцуем' },
        { id: 3, name: 'Чай кофе леденцы' },
        { id: 4, name: 'preset 4' },
        { id: 5, name: 'preset 5' },
        { id: 6, name: 'preset 6' },
    ])

    const { data: dataCurrencies } = useFetchCurrencies(undefined, { enabled: !!reportsParams })

    const reportTitle = useMemo(() => {
        const dateFrom = reportsParams && reportsParams[FieldName.dateFrom]
        const dateTo = reportsParams && reportsParams[FieldName.dateTo]
        const isCumulative = reportsParams && reportsParams[FieldName.isCumulative]

        if (dateFrom && dateTo) {
            const text = t('reports_period')
                .replace('%s', dateTimeFormat(dateFrom, i18n.language))
                .replace('%s', dateTimeFormat(dateTo, i18n.language))
            const subText = isCumulative ? `. ${t('reports_cumulative_total')}` : ''

            return `${text}${subText}`
        }

        return ''
    }, [reportsParams])

    const reportCurrency = useMemo(() => {
        return dataCurrencies && !!reportsParams?.currency
            ? dataCurrencies.find((item) => item.id === reportsParams.currency)?.showCode
            : undefined
    }, [dataCurrencies, reportsParams])

    const reportTableConfig = useMemo<TableConfiguration | undefined>(() => {
        if (!reportsData) {
            return undefined
        }

        return {
            data: {
                labels: [
                    createElement('div', { className: style.tableCol, key: 'date' }, [t('date')]),
                    createElement('div', { className: style.tableCol, key: 'reports_by_cash' }, [t('reports_by_cash')]),
                    createElement('div', { className: style.tableCol, key: 'reports_by_terminal' }, [t('reports_by_terminal')]),
                    createElement('div', { className: style.tableCol, key: 'reports_by_getaway' }, [t('reports_by_getaway')]),
                    createElement('div', { className: style.tableCol, key: 'reports_bank' }, [t('reports_bank')]),
                    createElement('div', { className: style.tableCol, key: 'reports_total_money' }, [t('reports_total_money')]),
                    createElement('div', { className: style.tableCol, key: 'reports_by_bonus' }, [t('reports_by_bonus')]),
                ],
                dataset: {
                    data: reportsData.map((item) => {
                        return [
                            createTableColValue('date', t('date'), dateTimeFormat(item.date, i18n.language)),
                            createTableColValue('reports_by_cash', t('reports_by_cash'), String(item.cash)),
                            createTableColValue('reports_by_terminal', t('reports_by_terminal'), String(item.terminal)),
                            createTableColValue('reports_by_getaway', t('reports_by_getaway'), String(item.getaway)),
                            createTableColValue('reports_bank', t('reports_bank'), String(item.bank)),
                            createTableColValue('reports_total_money', t('reports_total_money'), String(item.total)),
                            createTableColValue('reports_by_bonus', t('reports_by_bonus'), String(item.cv)),
                        ]
                    }),
                },
                footer: reportsData
                    .reduce<[string, number | undefined][]>((acc, item) => {
                        return [
                            ['reports_total', undefined],
                            ['reports_by_cash', acc[1] ? (acc[1][1] ?? 0) + item.cash : item.cash],
                            ['reports_by_terminal', acc[2] ? (acc[2][1] ?? 0) + item.terminal : item.terminal],
                            ['reports_by_getaway', acc[3] ? (acc[3][1] ?? 0) + item.getaway : item.getaway],
                            ['reports_bank', acc[4] ? (acc[4][1] ?? 0) + item.bank : item.bank],
                            ['reports_total_money', acc[5] ? (acc[5][1] ?? 0) + item.total : item.total],
                            ['reports_by_bonus', acc[6] ? (acc[6][1] ?? 0) + item.cv : item.cv],
                        ]
                    }, [Array(7).fill([])] as [string, number | undefined][])
                    .map(([key, value], index) => {
                        return index === 0
                            ? createElement('div', { className: cn(style.tableCol, style.tableFooterTitle), key }, [t(key)])
                            : createTableColValue(key, t(key), value !== undefined ? String(value) : '')
                    }),
            },
        }
    }, [reportsData])

    const handlerClickBack = () => {
        history.push(APP_URL.reports)
    }

    const handlerSuccessPreset = (value: { id: number } & PresetFormDataType) => {
        setPresetsData((prevState) => {
            return !prevState.find((item) => item.name === value.name)
                ? [value, ...prevState]
                : prevState
        })
    }

    const handlerDeletePreset = (value: number) => {
        setPresetsData((prevState) => {
            return prevState.filter((item) => item.id !== value)
        })
    }

    const handlerSuccessReportsPayment = (values: ReportsPaymentFormDataType) => {
        const today = new Date()
        const dateFrom = new Date(values[FieldName.dateFrom])
        const dateTo = new Date(values[FieldName.dateTo])
        const days = Math.ceil(Math.abs(dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24))

        setReportsParams(values)
        // FIXME fake data
        setReportsData(Array(days).fill({}).reduce((acc, item, index) => {
            const date = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() + index)
            const cash = index % 2 === 0 ? randomInteger(3000, 5000) : 0
            const getaway = randomInteger(1000, 20000)
            const bank = index % 3 === 0 ? randomInteger(2000, 15000) : 0
            const cv = randomInteger(5, 40)
            const total = cash + getaway + bank + cv

            return date <= today
                ? [
                    ...acc,
                    {
                        id: index + 1,
                        date,
                        cash,
                        terminal: 0,
                        getaway,
                        bank,
                        total,
                        cv,
                    },
                ]
                : acc
        }, []))
    }

    useEffect(() => {
        if (reportsData && reportsRef.current) {
            reportsRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
    }, [reportsData])

    return (
        <ContentContainer size="three-quarters">
            <div className={style.innerWrap}>
                <div className={style.contentInner}>
                    <PageHeader
                        title={t('reports_payment')}
                        onClickBack={handlerClickBack}
                    />
                    <Block classes={cn(style.block, style.block_form)}>
                        <div className={cn(style.blockHeader, style.blockHeader_bordered)}>
                            <div className={style.blockTitle}>
                                {t('reports_filter')}
                            </div>
                        </div>
                        <ReportsPaymentAction
                            classes={style.blockBody}
                            onSuccess={handlerSuccessReportsPayment}
                        />
                    </Block>
                </div>
                <div className={style.sidebarInner}>
                    <Block classes={cn(style.block, style.block_sidebar)}>
                        <div className={cn(style.blockHeader, style.blockHeader_bordered, style.blockHeader_row)}>
                            <div className={style.blockTitle}>
                                {t('presets')}
                            </div>
                            <div className={style.blockCount}>
                                {`(${presetsData.length})`}
                            </div>
                        </div>
                        <div className={style.blockBody}>
                            <div className={styleForm.fieldHead}>
                                {t('presets_available')}
                            </div>
                            <ReportsPresets
                                classes={style.presets}
                                data={presetsData}
                                onDelete={handlerDeletePreset}
                            />
                            <ReportsPaymentPresetAction
                                onSuccess={handlerSuccessPreset}
                            />
                        </div>
                    </Block>
                </div>
            </div>

            {reportsData && (
                <Block classes={cn(style.block, style.block_reports)}>
                    <div className={cn(
                        style.blockHeader,
                        style.blockHeader_row,
                        style.blockHeader_gap,
                        style.blockHeader_gray,
                    )}
                    >
                        <div className={style.reportsHeader}>
                            <div className={cn(
                                style.blockTitle,
                                { [style.blockTitle_flex]: reportsParams?.isCumulative },
                            )}
                            >
                                {reportTitle}
                                {!!reportsParams?.isCumulative && (
                                    <Tooltip
                                        classes={style.blockTitleTooltip}
                                        position="right"
                                        tooltip={t('reports_cumulative_total')}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                )}
                            </div>
                            <div className={style.reportsCurrency}>
                                {reportCurrency || (
                                    /* FIXME ? default currency is rub */
                                    <span dangerouslySetInnerHTML={{ __html: '&#8381;' }} />
                                )}
                            </div>
                        </div>
                        <div className={style.reportsActions} ref={reportsRef}>
                            {windowWidth >= BREAKPOINTS.tabletLandscape ? (
                                Object.entries(reportActionIcon).map(([key, value]) => (
                                    <Button
                                        classes={cn(
                                            style.reportsAction,
                                            { [style.reportsAction_active]: activeReportView === key },
                                        )}
                                        styleType="transparent"
                                        key={key}
                                        onClick={() => setActiveReportView(key as ReportView)}
                                    >
                                        <SvgResource
                                            isImgTag={false}
                                            classes={style.iconView}
                                            resourceKey={value}
                                            width={20}
                                            height={20}
                                        />
                                    </Button>
                                ))
                            ) : (
                                <CustomSelect.Select>
                                    <CustomSelect.Field
                                        classes={style.reportsActionSelectField}
                                    >
                                        <SvgResource
                                            isImgTag={false}
                                            classes={style.iconView}
                                            resourceKey={reportActionIcon[activeReportView]}
                                            width={20}
                                            height={20}
                                        />
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {Object.entries(reportActionIcon).map(([key, value], index) => (
                                            <CustomSelect.Option
                                                id={index}
                                                key={key}
                                                onClick={() => setActiveReportView(key as ReportView)}
                                            >
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={style.iconView}
                                                    resourceKey={value}
                                                    width={20}
                                                    height={20}
                                                />
                                            </CustomSelect.Option>
                                        ))}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                            )}
                        </div>
                    </div>
                    <div className={style.reports}>
                        {activeReportView === ReportView.table && (
                            <>
                                <div className={style.reportsTableTitle}>
                                    {t('reports_payment_amount')}
                                </div>
                                <div className={style.reportsTableView}>
                                    <ReportsTableView
                                        config={reportTableConfig}
                                    />
                                </div>
                            </>
                        )}
                        {activeReportView === ReportView.chartLine && (
                            <div className={style.reportsChartView}>
                                <ReportsChartView
                                    data={reportsData}
                                    options={{
                                        height: windowWidth < BREAKPOINTS.tabletLandscape ? 340 : undefined,
                                    }}
                                />
                            </div>
                        )}
                        {activeReportView === ReportView.chartBar && (
                            <div className={style.reportsChartView}>
                                <ReportsBarView
                                    isCumulative={!!reportsParams?.isCumulative}
                                    data={reportsData}
                                    options={{
                                        height: windowWidth < BREAKPOINTS.tabletLandscape ? 340 : undefined,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Block>
            )}
        </ContentContainer>
    )
}

export default ReportsPayment
