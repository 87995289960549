import React from 'react'
import cn from 'classnames'

import { Avatar, Button } from 'components'
import style from './OrdersOrderOwner.module.css'

type OrdersOrderOwnerPropType = {
    classes?: string
}

const OrdersOrderOwner: React.FC<OrdersOrderOwnerPropType> = ({ classes }) => {
    return (
        <div className={cn(style.wrap, classes)}>
            <Avatar
                classes={style.avatar}
                src={undefined}
                width={90}
                height={90}
            />
            <div className={style.user}>
                <div className={style.userName}>
                    Mahdii
                </div>
                <div className={style.userSurname}>
                    Halyna&Oleksandr
                </div>
            </div>
            <div className={style.data}>
                <div className={style.dataAccount}>
                    Account ID: 66739
                </div>
                <div className={style.dataMember}>
                    Member ID: 001-152720
                </div>
            </div>
            <div className={style.actions}>
                <Button
                    classes={style.action}
                    size="size44"
                    // TODO
                    text="В профиль"
                />
            </div>
        </div>
    )
}

export default OrdersOrderOwner
