import React from 'react'
import cn from 'classnames'

import { Button } from 'components'
import { OrderPaymentStatusAction } from 'form-actions'
import style from './OrdersOrderInfo.module.css'

type OrdersOrderInfoPropType = {
    classes?: string
    onClickOrder: () => void
}

const OrdersOrderInfo: React.FC<OrdersOrderInfoPropType> = ({ classes, onClickOrder }) => {
    return (
        <div className={cn(style.wrap, classes)}>
            <OrderPaymentStatusAction />

            <div className={style.actions}>
                <Button
                    classes={style.action}
                    styleType="text"
                    // TODO
                    text="Детали заказа"
                    onClick={onClickOrder}
                />
            </div>
        </div>
    )
}

export default OrdersOrderInfo
