import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import cn from 'classnames'

import { CustomSelect, Tag } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderDeliveryTypeForm.module.css'

export enum FieldName {
    deliveryMethod = 'deliveryMethod',
    deliveryStatus = 'deliveryStatus',
}

export type FormDataType = {
    [FieldName.deliveryMethod]: number
    [FieldName.deliveryStatus]: number
}

export type OrderDeliveryStatusFormPropType = {
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const METHODS = [
    { id: 1, name: 'Доставка по адресу' },
    { id: 2, name: 'Самовывоз' },
]

// FIXME on ready back
// eslint-disable-next-line
const STATUSES = [
    { id: 1, name: 'В пути' },
    { id: 2, name: 'В пути' },
]

const OrderDeliveryTypeForm: React.FC<OrderDeliveryStatusFormPropType> = ({
    isSubmitting,
    onSubmit,
}) => {
    const methodsData = useMemo(() => {
        return METHODS.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Тип доставки
                </div>
                <Field
                    <FormDataType[FieldName.deliveryStatus]>
                    name={FieldName.deliveryStatus}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {!!input.value && methodsData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {METHODS.map((item) => (
                                    <CustomSelect.Option
                                        id={item.id}
                                        key={item.id}
                                        onClick={() => input.onChange(item.id)}
                                    >
                                        {item.name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.fieldText}>
                    {/* TODO */}
                    Статус
                </div>
                <div className={style.fieldValue}>
                    <Tag classes={style.tag} classesContent={style.tagContent}>
                        В пути
                    </Tag>
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.fieldText}>
                    {/* TODO */}
                    Трек-номер для отслеживания
                </div>
                <div className={style.fieldValue}>
                    3297716376
                </div>
            </div>
        </form>
    )
}

export default OrderDeliveryTypeForm
