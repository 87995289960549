import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer, Block } from 'layout'
import { PageTitle, Breadcrumbs, Modal } from 'components'
import { OrderDetailsAction, OrderDeliveryDetailsAction, OrderCommentAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import {
    OrdersOrderOwner,
    OrdersOrderInfo,
    OrdersOrderDelivery,
    OrdersOrderContentsList,
    OrdersOrderHistoryList,
} from './components'
import style from './OrdersOrder.module.css'

// FIXME
type TContentsData = {
    id: number
    name: string
    count: number
    price: number
    paymentForCv: number
    paymentCv: number
    sum: number
    currency: string
}

type THistoryData = {
    id: number
    date: string
    action: string
}

type TContentsColumn = {
    id: number
    key: keyof TContentsData
    name: string
}

type THistoryColumn = {
    id: number
    key: keyof THistoryData
    name: string
}

const CONTENTS_LIST_COLUMNS: TContentsColumn[] = [
    {
        id: 1,
        key: 'name',
        name: 'Название',
    },
    {
        id: 2,
        key: 'count',
        name: 'Кол-во',
    },
    {
        id: 3,
        key: 'price',
        name: 'Цена',
    },
    {
        id: 4,
        key: 'paymentForCv',
        name: 'Оплата за бонусы',
    },
    {
        id: 5,
        key: 'paymentCv',
        name: 'Оплата бонусами',
    },
    {
        id: 6,
        key: 'sum',
        name: 'Сумма',
    },
]

const HISTORY_LIST_COLUMNS: THistoryColumn[] = [
    {
        id: 1,
        key: 'date',
        name: 'Дата',
    },
    {
        id: 2,
        key: 'action',
        name: 'Действие',
    },
]

// FIXME
const dataOrder = {
    id: 1,
    date: '2024-08-12 11:46:25',
    goods: [
        {
            id: 10,
            name: 'Maglia Hida',
            count: 2,
            price: 12000,
            paymentForCv: 100,
            paymentCv: 200,
            sum: 12000,
            currency: '€',
        },
        {
            id: 20,
            name: 'Elia Polo Shirt',
            count: 1,
            price: 10000,
            paymentForCv: 0,
            paymentCv: 0,
            sum: 10000,
            currency: '€',
        },
    ],
    history: [
        {
            id: 1,
            date: '2024-08-12 11:46:25',
            action: 'Osmanovic Ivana (ivana.osmanovic@freedomgroupint.com) changed comment → L/IO/12.08',
        },
    ],
}

const OrdersOrder: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const [windowWidth] = useWindowResize()
    const { t } = useTranslation()

    const [isOpenModalOrderDetails, setIsOpenModalOrderDetails] = useState(false)
    const [isOpenModalDeliveryDetails, setIsOpenModalDeliveryDetails] = useState(false)

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        return [
            { id: 1, name: t('orders'), url: APP_URL.companyOrders },
            { id: 2, name: `${t('order')} ${id}` },
        ]
    }, [id])

    return (
        <>
            <ContentContainer classes={style.content} size="three-quarters">
                <PageTitle>
                    <Breadcrumbs items={breadcrumbs} />
                </PageTitle>

                <CardContainer classes={style.cardContainer} gap="15px">
                    <Block classes={style.blockCard}>
                        <Block.Header classes={style.blockCardHeader}>
                            <div className={style.blockTitle}>
                                {/* TODO */}
                                Владелец заказа
                            </div>
                        </Block.Header>
                        <OrdersOrderOwner classes={style.blockCardBody} />
                    </Block>
                    <Block classes={style.blockCard}>
                        <Block.Header classes={style.blockCardHeader}>
                            <div className={style.blockTitle}>
                                {`${t('order')} ${t('from')}`}
                            </div>
                            <div className={style.blockHeaderInfo}>
                                {/* TODO */}
                                2024-08-12 11:46:25
                            </div>
                        </Block.Header>
                        <OrdersOrderInfo
                            classes={style.blockCardBody}
                            onClickOrder={() => setIsOpenModalOrderDetails(true)}
                        />
                    </Block>
                    <Block classes={style.blockCard}>
                        <Block.Header classes={style.blockCardHeader}>
                            <div className={style.blockTitle}>
                                {t('delivery')}
                            </div>
                        </Block.Header>
                        <OrdersOrderDelivery
                            classes={style.blockCardBody}
                            onClickDelivery={() => setIsOpenModalDeliveryDetails(true)}
                        />
                    </Block>
                </CardContainer>
                <Block classes={cn(style.block, style.block_row)}>
                    <Block.Header classes={style.blockHeader}>
                        <Block.HeaderInner classes={style.blockHeaderInner}>
                            <div className={style.blockTitle}>
                                {t('order_contents')}
                            </div>
                        </Block.HeaderInner>
                    </Block.Header>
                    <div className={style.blockBody}>
                        <OrdersOrderContentsList
                            columns={CONTENTS_LIST_COLUMNS}
                            data={dataOrder?.goods}
                        />
                    </div>
                </Block>
                <Block classes={cn(style.block, style.block_row, style.block_gray)}>
                    <Block.Header classes={style.blockHeader}>
                        <div className={style.blockTitle}>
                            {/* TODO */}
                            Комментарии к заказу
                        </div>
                    </Block.Header>
                    <div className={style.blockBody}>
                        <OrderCommentAction />
                    </div>
                </Block>
                <Block classes={cn(style.block, style.block_row)}>
                    <Block.Header classes={style.blockHeader}>
                        <Block.HeaderInner classes={cn(style.blockHeaderInner, style.blockHeaderInner_gray)}>
                            <div className={style.blockTitle}>
                                {/* TODO */}
                                История изменений
                            </div>
                        </Block.HeaderInner>
                    </Block.Header>
                    <div className={style.blockBody}>
                        <OrdersOrderHistoryList
                            columns={HISTORY_LIST_COLUMNS}
                            data={dataOrder?.history}
                        />
                    </div>
                </Block>
            </ContentContainer>

            <Modal
                mobileFullScreen
                isOpen={isOpenModalOrderDetails}
                size="small2Center"
                onClose={() => setIsOpenModalOrderDetails(false)}
            >
                <Modal.Header
                    isBackButton={windowWidth < BREAKPOINTS.tabletLandscape}
                    isCloseButton={windowWidth >= BREAKPOINTS.tabletLandscape}
                    classes={style.modalHeader}
                    classesTitle={style.modalTitle}
                    // TODO
                    title={`Детали заказа №${123456}`}
                    titlePos={windowWidth < BREAKPOINTS.tabletLandscape ? 'center' : 'left'}
                />
                <Modal.Body classes={style.modalBody}>
                    <OrderDetailsAction />
                </Modal.Body>
            </Modal>
            <Modal
                mobileFullScreen
                isOpen={isOpenModalDeliveryDetails}
                size="small2Center"
                onClose={() => setIsOpenModalDeliveryDetails(false)}
            >
                <Modal.Header
                    isBackButton={windowWidth < BREAKPOINTS.tabletLandscape}
                    isCloseButton={windowWidth >= BREAKPOINTS.tabletLandscape}
                    classes={style.modalHeader}
                    classesTitle={style.modalTitle}
                    // TODO
                    title="Детали доставки"
                    titlePos={windowWidth < BREAKPOINTS.tabletLandscape ? 'center' : 'left'}
                />
                <Modal.Body classes={style.modalBody}>
                    <OrderDeliveryDetailsAction />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrdersOrder
