import React from 'react'

import { CompanyOrdersFilterAction } from 'form-actions'

type CompanyOrdersFilterPropType = {
    onSuccess: () => void
}

const CompanyOrdersFilter: React.FC<CompanyOrdersFilterPropType> = ({ onSuccess }) => {
    return (
        <CompanyOrdersFilterAction
            onSuccess={onSuccess}
        />
    )
}

export default CompanyOrdersFilter
