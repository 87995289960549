import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { AppMode, AppMenuKey } from 'enums'
import { APP_URL } from 'config/app'
import { useAppMode } from 'containers/App/hooks'
import { useFetchPersonalStores } from 'containers/Market/hooks'
import { useAppSelector } from 'store'
import { MenuNavItem, MenuNavLink, MenuNavDropDown } from './components'
import style from './MenuNav.module.css'

type MenuNavPropType = {
    isMobile?: boolean
}

type TMenuItems = Record<string, IMenuNavItemData>

const MENU_ITEMS: TMenuItems = {
    [AppMenuKey.stores]: {
        id: AppMenuKey.stores,
        translateKey: 'stores',
        icon: 'ic_shop_cart_svg',
        iconClassName: style.icon,
        url: APP_URL.stores,
        sort: 1,
        mode: AppMode.business,
        mobile: true,
    },
    [AppMenuKey.market]: {
        id: AppMenuKey.market,
        translateKey: 'stores',
        icon: 'ic_shop_cart_svg',
        iconClassName: style.icon,
        url: APP_URL.market,
        sort: 1,
        mode: AppMode.user,
        mobile: true,
    },
    [AppMenuKey.companyOrders]: {
        id: AppMenuKey.companyOrders,
        translateKey: 'orders',
        icon: 'orders_svg',
        iconClassName: style.icon,
        url: APP_URL.companyOrders,
        sort: 2,
        mode: AppMode.business,
    },
    [AppMenuKey.warehouse]: {
        id: AppMenuKey.warehouse,
        translateKey: 'warehouse',
        icon: 'wl_crm_warehouse',
        iconClassName: style.icon,
        url: APP_URL.warehouse,
        sort: 3,
        mode: AppMode.business,
        mobile: true,
    },
    [AppMenuKey.clients]: {
        id: AppMenuKey.clients,
        translateKey: 'business_menu_clients_icon_title',
        icon: 'ic_clients',
        iconClassName: style.icon,
        url: APP_URL.clients,
        sort: 4,
        mode: AppMode.business,
        mobile: true,
    },
    [AppMenuKey.reports]: {
        id: AppMenuKey.reports,
        translateKey: 'reports',
        icon: 'ic_report_svg',
        iconClassName: style.icon,
        sort: 5,
        url: APP_URL.reports,
        mobile: true,
    },
    [AppMenuKey.feed]: {
        id: AppMenuKey.feed,
        translateKey: 'feed',
        icon: 'ic_tab2_feed_svg',
        iconClassName: style.icon,
        url: APP_URL.lenta,
        countEvent: {
            key: 'feed_posts_count',
            value: 0,
        },
        sort: 6,
    },
    [AppMenuKey.friends]: {
        id: AppMenuKey.friends,
        translateKey: 'friends',
        icon: 'ic_tab_friends_svg',
        iconClassName: style.icon,
        url: APP_URL.friends,
        countEvent: {
            key: 'friend_requests_count',
            value: 0,
        },
        sort: 7,
        mobile: true,
    },
    [AppMenuKey.wallet]: {
        id: AppMenuKey.wallet,
        translateKey: 'icon_menu_wallet',
        icon: 'ic_tab2_wallet_svg',
        iconClassName: style.icon,
        url: APP_URL.wallet,
        countEvent: {
            key: 'wallet_count',
            value: 0,
        },
        sort: 8,
    },
    [AppMenuKey.messenger]: {
        id: AppMenuKey.messenger,
        translateKey: 'menu_chat',
        icon: 'ic_chat_v2_svg',
        iconClassName: style.icon,
        url: APP_URL.messenger,
        countEvent: {
            key: 'chat_count',
            value: 0,
        },
        sort: 9,
        burger: true,
    },
    [AppMenuKey.support]: {
        id: AppMenuKey.support,
        translateKey: 'ui_support_create_ticket_title',
        icon: 'ic_support_v2_svg',
        iconClassName: style.icon,
        url: APP_URL.support,
        notify: {
            key: 'support_count',
            value: false,
        },
        sort: 10,
        burger: true,
    },
    [AppMenuKey.company]: {
        id: AppMenuKey.company,
        translateKey: 'business_menu_company_icon_title',
        icon: 'ic_menu_company_svg',
        iconClassName: style.icon,
        url: APP_URL.company,
        sort: 11,
        mode: AppMode.business,
    },
}

const MenuNav: React.FC<MenuNavPropType> = ({ isMobile = false }) => {
    const { appMode, isBusinessMode } = useAppMode()

    const { forbidden: forbiddenConfig } = useAppSelector((state) => state.app.config)
    const counts = useAppSelector((state) => state.counts)

    const { data: dataPersonalStores } = useFetchPersonalStores({ enabled: isBusinessMode })

    const itemsValues = useMemo(() => {
        const countPersonalStores = dataPersonalStores?.length

        const items = Object.values(MENU_ITEMS).reduce<IMenuNavItemData[]>((acc, item) => {
            const menuKey = AppMenuKey[item.id]
            const isAllowedMode = item.mode ? item.mode === appMode : true
            const isForbiddenBusiness = isBusinessMode && forbiddenConfig[menuKey] === false

            if (!isAllowedMode || isForbiddenBusiness) {
                return acc
            }

            return [
                ...acc,
                {
                    ...item,
                    /** Link to single shop for business user */
                    url: item.id === AppMenuKey.stores && countPersonalStores === 1
                        ? generatePath(APP_URL.shop, { id: dataPersonalStores![0].id })
                        : item.url,
                    countValue: item.countValue && counts[item.countValue.key] !== undefined
                        ? { ...item.countValue, value: counts[item.countValue.key] as number }
                        : undefined,
                    countEvent: item.countEvent && counts[item.countEvent.key] !== undefined
                        ? { ...item.countEvent, value: counts[item.countEvent.key] as number }
                        : undefined,
                    notify: item.notify && counts[item.notify.key] !== undefined
                        ? { ...item.notify, value: !!counts[item.notify.key] }
                        : undefined,
                },
            ]
        }, [])

        return items.sort((a, b) => {
            return typeof a.sort === 'number' && typeof b.sort === 'number' && a.sort > b.sort ? 1 : -1
        })
    }, [forbiddenConfig, dataPersonalStores, counts])

    const menuMainItems = useMemo(() => {
        return itemsValues.reduce<IMenuNavItemData[]>((acc, item) => {
            if ((isMobile && !item.mobile) || item.burger) {
                return acc
            }

            return [...acc, item]
        }, [])
    }, [isMobile, itemsValues])

    const menuDropDownItems = useMemo(() => {
        return isMobile
            ? itemsValues.filter((item) => !item.mobile)
            : []
    }, [isMobile, itemsValues])

    return (
        <ul className={cn(style.menuNav, { [style.menuNav_mobile]: isMobile })}>
            {menuMainItems.map((item) => (
                <MenuNavItem key={item.id}>
                    <MenuNavLink
                        isDisabled={item.disabled}
                        mode={appMode}
                        data={item}
                    />
                </MenuNavItem>
            ))}

            {isMobile && !!menuDropDownItems.length && (
                <MenuNavItem>
                    <MenuNavDropDown items={menuDropDownItems} />
                </MenuNavItem>
            )}
        </ul>
    )
}

export default MenuNav
