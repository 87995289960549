import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { AppMode } from 'enums'
import { useSelector } from 'react-redux'
import { APP_URL } from 'config/app'
import {
    AuthPage,
    HomePage,
    LKPage,
    SupportPage,
    ServicePage,
} from 'pages'
import { Logout } from 'containers'
import * as appSelectors from 'containers/App/app-selectors'
import { useApp, useAppMode } from 'containers/App/hooks'
import RouteClient from './RouteClient'

type RoutesPropType = {
    isAuthorized: boolean
}

const Routes: React.FC<RoutesPropType> = ({ isAuthorized }) => {
    const { mainPrivateRoute } = useApp()
    const { isBusinessMode } = useAppMode()

    const { forbidden: forbiddenConfig } = useSelector(appSelectors.config)

    return (
        <Switch>
            <RouteClient isAuthorized={isAuthorized} path={APP_URL.auth} component={AuthPage} />
            <RouteClient isAuthorized={isAuthorized} path={APP_URL.registrationUser} component={HomePage} />
            <RouteClient isAuthorized={isAuthorized} path={APP_URL.registrationCompany} component={HomePage} />
            <RouteClient
                isAuthorized={isAuthorized}
                path={APP_URL.registrationStoreSettings}
                component={HomePage}
            />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.logout} component={Logout} />

            <RouteClient
                exact
                isPrivate={isAuthorized}
                isAuthorized={isAuthorized}
                path={APP_URL.support}
                component={isAuthorized ? LKPage : SupportPage}
            />
            <RouteClient
                isPrivate={isAuthorized}
                isAuthorized={isAuthorized}
                path={APP_URL.ticket}
                component={isAuthorized ? LKPage : SupportPage}
            />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.account} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.profile} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.userSettings} component={LKPage} />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.settings} component={LKPage} />

            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.lenta}
                component={LKPage}
                forbidden={isBusinessMode && forbiddenConfig.feed === false}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.post}
                component={LKPage}
                forbidden={isBusinessMode && forbiddenConfig.feed === false}
            />

            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.shopsFavorite}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.shopsSettings}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.shop}
                only={AppMode.business}
                component={LKPage}
            />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.market}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.category}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.stores}
                only={AppMode.business}
                component={LKPage}
            />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.storeCreate}
                only={AppMode.business}
                forbidden={isBusinessMode && forbiddenConfig.storeAdd === false}
                component={LKPage}
            />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.store}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.storeSettings}
                only={AppMode.business}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.storeProfile}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.storeEdit}
                only={AppMode.business}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.catalog}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.basket}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.orders}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.orderMake}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.orderPayment}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.goods}
                only={AppMode.user}
                component={LKPage}
            />

            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.friends} component={LKPage} />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.friendsRequest}
                component={LKPage}
            />

            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.clients}
                only={AppMode.business}
                component={LKPage}
            />

            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.reports}
                forbidden={isBusinessMode && forbiddenConfig.reports === false}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.report}
                forbidden={isBusinessMode && forbiddenConfig.reports === false}
                component={LKPage}
            />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.messenger} component={LKPage} />

            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.rating}
                only={AppMode.user}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.company}
                only={AppMode.business}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.companyOrders}
                only={AppMode.business}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.warehouse}
                only={AppMode.business}
                component={LKPage}
            />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.community} component={LKPage} />

            {/* dev */}
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.kicks}
                forbidden={isBusinessMode && forbiddenConfig.kicks === false}
                component={LKPage}
            />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.wallet}
                forbidden={isBusinessMode && forbiddenConfig.wallet === false}
                component={LKPage}
            />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.friends} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.map} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.popular} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.invite} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.receipt} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.lessons} component={LKPage} />
            {/* /dev */}

            {/* for create link redirect */}
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.app} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.kick} component={LKPage} />

            {/* for service */}
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.service}
                component={ServicePage}
            />

            <Redirect from="*" to={isAuthorized ? mainPrivateRoute : APP_URL.auth} />
        </Switch>
    )
}

export default Routes
