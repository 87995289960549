import React, { useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    Input,
    DatePicker,
    CustomSelect,
    SvgResource,
    Modal,
} from 'components'
import { defaultDateFormat, dateTimeFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderPaymentStatusForm.module.css'

export enum FieldName {
    datePayment = 'datePayment',
    paymentStatus = 'paymentStatus',
}

export type FormDataType = {
    [FieldName.datePayment]: string
    [FieldName.paymentStatus]: number
}

export type OrderStatusFormPropType = {
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const DATE_TIME_OPTS: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
    timeStyle: 'medium',
}

// FIXME on ready back
const STATUSES = [
    { id: 1, name: 'Оплачено' },
    { id: 2, name: 'Оплачено' },
]

const OrderPaymentStatusForm: React.FC<OrderStatusFormPropType> = ({
    isSubmitting,
    onSubmit,
}) => {
    const { i18n } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values } = useFormState<FormDataType>()

    const [dateCalendar, setDateCalendar] = useState(new Date())
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)

    const statusesData = useMemo(() => {
        return STATUSES.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    return (
        <>
            <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {/* TODO */}
                        Дата оплаты
                    </div>
                    <Field
                        <FormDataType[FieldName.datePayment]>
                        name={FieldName.datePayment}
                        render={({ input, meta }) => (
                            <div className={cn(styleForm.group, style.field)}>
                                <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                    <Input
                                        styleType="defaultBusiness"
                                        name={input.name}
                                        value={input.value
                                        && dateTimeFormat(input.value, i18n.language, DATE_TIME_OPTS)}
                                        onFocus={(e) => {
                                            e.currentTarget.blur()
                                        }}
                                        onBlur={() => {
                                            setDateCalendar(input.value ? new Date(input.value) : new Date())
                                            setIsOpenModalCalendar(true)
                                        }}
                                        onChange={() => {}}
                                    />
                                    <div className={styleForm.error}>
                                        {meta.dirty && meta.error}
                                    </div>
                                </div>
                                <div className={cn(styleForm.groupItem, styleForm.groupItem_alignCenter)}>
                                    <SvgResource
                                        isImgTag={false}
                                        classes={cn(style.fieldIcon, style.iconCalendar)}
                                        resourceKey="ic_kicks_calendar2_svg"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                        <div className={cn(styleForm.groupItem, style.fieldText)}>
                            {/* TODO */}
                            Сумма к оплате
                        </div>
                        <div className={cn(styleForm.groupItem, style.fieldValue)}>
                            22 000 €
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {/* TODO */}
                        Статус оплаты
                    </div>
                    <Field
                        <FormDataType[FieldName.paymentStatus]>
                        name={FieldName.paymentStatus}
                        render={({ input, meta }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.fieldSelect}>
                                    {!!input.value && statusesData[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {STATUSES.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                        <div className={cn(styleForm.groupItem, style.fieldText)}>
                            {/* TODO */}
                            Сумма возврата
                        </div>
                        <div className={cn(styleForm.groupItem, style.fieldValue)}>
                            0 €
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCalendar}
                size="extraSmallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        isSetTime
                        date={dateCalendar}
                        dateSelected={values[FieldName.datePayment]
                            ? new Date(values[FieldName.datePayment])
                            : undefined}
                        onChange={(value) => {
                            change(FieldName.datePayment, defaultDateFormat(value, true, true))
                            setIsOpenModalCalendar(false)
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderPaymentStatusForm
