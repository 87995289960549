import React from 'react'
import { Form } from 'react-final-form'

import { FormDataType } from 'forms/CompanyOrdersFilterForm/CompanyOrdersFilterForm'
import { CompanyOrdersFilterForm } from 'forms'
import { useAppSelector } from 'store'

type CompanyOrdersFilterActionPropType = {
    onReset?: () => void
    onSuccess: (value: FormDataType) => void
}

/**
 * FIXME fake data
 */
const goods = [
    { id: 1, name: 'Рамен с курицей' },
    { id: 2, name: 'Рамен со свининой' },
    { id: 3, name: 'Рамен овощной' },
    { id: 4, name: 'Рамен с креветками' },
]

const CompanyOrdersFilterAction: React.FC<CompanyOrdersFilterActionPropType> = ({ onReset = () => {}, onSuccess }) => {
    const countries = useAppSelector((state) => state.app.countries)

    const handlerSubmit = (params: FormDataType) => {
        onSuccess(params)
    }

    return (
        <Form
            onSubmit={handlerSubmit}
            render={({ handleSubmit }) => (
                <CompanyOrdersFilterForm
                    goods={goods}
                    countries={countries}
                    onReset={onReset}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default CompanyOrdersFilterAction
