import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormState } from 'react-final-form'
import cn from 'classnames'

import { ICountry } from 'interfaces'
import { CardContainer, Block } from 'layout'
import {
    Button,
    CountryDataSelect,
    CustomSelect,
    Input,
    Tabs,
    Tooltip,
    SvgResource,
    Suggest,
    Switch,
} from 'components'
// import { dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './CompanyOrdersFilterForm.module.css'

export enum FieldName {
    orderId = 'orderId',
    orderDateFrom = 'orderDateFrom',
    orderDateTo = 'orderDateTo',
    paymentDateFrom = 'paymentDateFrom',
    paymentDateTo = 'paymentDateTo',
    userId = 'userId',
    userName = 'userName',
    userPhoneEmail = 'userPhoneEmail',
    goods = 'goods',
    country = 'country',
    store = 'store',
    vendor = 'vendor',
    isNeedDelivery = 'isNeedDelivery',
    deliveryAddress = 'deliveryAddress',
    deliveryStatus = 'deliveryStatus',
    status = 'status',
    payment = 'payment',
}

export type FormDataType = {
    [FieldName.orderId]?: number
    [FieldName.orderDateFrom]?: string
    [FieldName.orderDateTo]?: string
    [FieldName.paymentDateFrom]?: string
    [FieldName.paymentDateTo]?: string
    [FieldName.userId]?: number
    [FieldName.userName]?: number
    [FieldName.userPhoneEmail]?: number
    [FieldName.goods]?: number[]
    [FieldName.country]?: number
    [FieldName.store]?: number[]
    [FieldName.vendor]?: number
    [FieldName.isNeedDelivery]?: 0|1
    [FieldName.deliveryAddress]?: string
    [FieldName.deliveryStatus]?: string
    [FieldName.status]?: string
    [FieldName.payment]?: string
}

type CompanyOrdersFilterFormPropType = {
    classes?: string
    isSubmitting?: boolean
    countries: ICountry[]
    goods: { id: number, name: string }[] // FIXME on ready api request
    stores?: { id: number, name: string }[] // FIXME on ready api request
    onReset: () => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.goods]: [],
}

const CompanyOrdersFilterForm: React.FC<CompanyOrdersFilterFormPropType> = ({
    classes,
    isSubmitting,
    countries,
    goods,
    stores,
    onReset,
    onSubmit,
}) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const { values } = useFormState<FormDataType>()

    const [activeTabDateId, setActiveTabDateId] = useState(1)

    const countriesData: Record<number, ICountry> = useMemo(() => {
        return countries
            ? countries.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
            : {}
    }, [countries])
    // eslint-disable-next-line
    const goodsData: Record<number, string> = useMemo(() => {
        return goods
            ? goods.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [goods])

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <CardContainer gap="15px">
                <Block classes={style.block}>
                    <div className={style.blockHeader}>
                        <div className={style.blockTitle}>
                            {/* TODO */}
                            Заказ
                        </div>
                        <div>
                            <Button
                                classes={style.blockAction}
                                styleType="text"
                                text={t('clear')}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    ID заказа
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            ID заказа
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.orderId]>
                            name={FieldName.orderId}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                    <Tabs
                        classes={style.tabs}
                        classesItem={style.tabsItem}
                        classesText={style.tabsText}
                        // TODO
                        items={[
                            { id: 1, name: 'Дата создания' },
                            { id: 2, name: 'Дата оплаты' },
                        ]}
                        active={activeTabDateId}
                        onChange={(id) => setActiveTabDateId(id)}
                    />
                    {activeTabDateId === 1 && (
                        <>
                            <div className={cn(
                                styleForm.row,
                                styleForm.row_20,
                                styleForm.group,
                                styleForm.group_alignCenter,
                            )}
                            >
                                <div className={cn(styleForm.groupItem, style.fieldTextBefore)}>
                                    {/* TODO */}
                                    С
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <Field
                                        <FormDataType[FieldName.orderDateFrom]>
                                        name={FieldName.orderDateFrom}
                                        render={({ input, meta }) => (
                                            <Input
                                                {...input}
                                                styleType="defaultBusiness"
                                            />
                                        )}
                                    />
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <SvgResource
                                        isImgTag={false}
                                        classes={cn(style.fieldIcon, style.iconCalendar)}
                                        resourceKey="ic_kicks_calendar2_svg"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className={cn(
                                styleForm.row,
                                styleForm.row_20,
                                styleForm.group,
                                styleForm.group_alignCenter,
                            )}
                            >
                                <div className={cn(styleForm.groupItem, style.fieldTextBefore)}>
                                    {/* TODO */}
                                    По
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <Field
                                        <FormDataType[FieldName.orderDateTo]>
                                        name={FieldName.orderDateTo}
                                        render={({ input, meta }) => (
                                            <Input
                                                {...input}
                                                styleType="defaultBusiness"
                                            />
                                        )}
                                    />
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <SvgResource
                                        isImgTag={false}
                                        classes={cn(style.fieldIcon, style.iconCalendar)}
                                        resourceKey="ic_kicks_calendar2_svg"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {activeTabDateId === 2 && (
                        <>
                            <div className={cn(
                                styleForm.row,
                                styleForm.row_20,
                                styleForm.group,
                                styleForm.group_alignCenter,
                            )}
                            >
                                <div className={cn(styleForm.groupItem, style.fieldTextBefore)}>
                                    {/* TODO */}
                                    С
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <Field
                                        <FormDataType[FieldName.paymentDateFrom]>
                                        name={FieldName.paymentDateFrom}
                                        render={({ input, meta }) => (
                                            <Input
                                                {...input}
                                                styleType="defaultBusiness"
                                            />
                                        )}
                                    />
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <SvgResource
                                        isImgTag={false}
                                        classes={cn(style.fieldIcon, style.iconCalendar)}
                                        resourceKey="ic_kicks_calendar2_svg"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className={cn(
                                styleForm.row,
                                styleForm.row_20,
                                styleForm.group,
                                styleForm.group_alignCenter,
                            )}
                            >
                                <div className={cn(styleForm.groupItem, style.fieldTextBefore)}>
                                    {/* TODO */}
                                    По
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <Field
                                        <FormDataType[FieldName.paymentDateTo]>
                                        name={FieldName.paymentDateTo}
                                        render={({ input, meta }) => (
                                            <Input
                                                {...input}
                                                styleType="defaultBusiness"
                                            />
                                        )}
                                    />
                                </div>
                                <div className={cn(styleForm.groupItem)}>
                                    <SvgResource
                                        isImgTag={false}
                                        classes={cn(style.fieldIcon, style.iconCalendar)}
                                        resourceKey="ic_kicks_calendar2_svg"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </Block>
                <Block classes={style.block}>
                    <div className={style.blockHeader}>
                        <div className={style.blockTitle}>
                            Получатель
                        </div>
                        <div>
                            <Button
                                classes={style.blockAction}
                                styleType="text"
                                text={t('clear')}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                            {/* TODO */}
                            ФИО получателя
                        </div>
                        <Field
                            <FormDataType[FieldName.userName]>
                            name={FieldName.userName}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Код пользователя-получателя
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Код пользователя-получателя
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.userId]>
                            name={FieldName.userId}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                            {/* TODO */}
                            Телефон, e-mail
                        </div>
                        <Field
                            <FormDataType[FieldName.userPhoneEmail]>
                            name={FieldName.userPhoneEmail}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                </Block>
                <Block classes={style.block}>
                    <div className={style.blockHeader}>
                        <div className={style.blockTitle}>
                            {t('stores')}
                        </div>
                        <div>
                            <Button
                                classes={style.blockAction}
                                styleType="text"
                                text={t('clear')}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                            {t('country')}
                        </div>
                        <Field
                            <FormDataType[FieldName.country]>
                            name={FieldName.country}
                            render={({ input }) => (
                                <CountryDataSelect
                                    isCanEmpty
                                    isShowSelectArrow
                                    classesField={style.fieldSelect}
                                    countries={countries}
                                    selected={input.value ? countriesData[input.value] : undefined}
                                    selectedIsIcon={false}
                                    onChange={(value) => {
                                        return input.onChange(value.id)
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    {t('reports_store')}
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('reports_store')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.store]>
                            name={FieldName.store}
                            render={({ input }) => (
                                <CustomSelect.Select>
                                    <CustomSelect.Field
                                        classes={style.fieldSelect}
                                    >
                                        {/* TODO */}
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {stores?.map((item) => (
                                            <CustomSelect.Option
                                                id={item.id}
                                                isActive={input.value && input.value.includes(item.id)}
                                                key={item.id}
                                                onClick={() => input.onChange([...input.value ?? [], item.id])}
                                            >
                                                {item.name}
                                            </CustomSelect.Option>
                                        ))}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                            )}
                        />
                    </div>
                </Block>
                <Block classes={style.block}>
                    <div className={style.blockHeader}>
                        <div className={style.blockTitle}>
                            Товар
                        </div>
                        <div>
                            <Button
                                classes={style.blockAction}
                                styleType="text"
                                text={t('clear')}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Товары
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Товары
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.goods]>
                            name={FieldName.goods}
                            render={({ input }) => (
                                <Suggest
                                    isShowArrow
                                    classesField={style.fieldSelect}
                                    items={[
                                        { id: 1, value: 'Product name' },
                                        { id: 2, value: 'Product name 2' },
                                        { id: 3, value: 'Product name 3' },
                                        { id: 4, value: 'Product name 4' },
                                        { id: 5, value: 'Product name 5' },
                                        { id: 6, value: 'Product name 6' },
                                        { id: 7, value: 'Product name 7' },
                                        { id: 8, value: 'Product name 8' },
                                        { id: 9, value: 'Product name 9' },
                                    ]}
                                    onSearch={() => {}}
                                    onSelect={() => {}}
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Артикулы
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Артикулы
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.vendor]>
                            name={FieldName.vendor}
                            render={({ input }) => (
                                <Suggest
                                    isShowArrow
                                    classesField={style.fieldSelect}
                                    items={[]}
                                    onSearch={() => {}}
                                    onSelect={() => {}}
                                />
                            )}
                        />
                    </div>
                </Block>
                <Block classes={style.block}>
                    <div className={style.blockHeader}>
                        <div className={style.blockTitle}>
                            Доставка
                        </div>
                        <div>
                            <Button
                                classes={style.blockAction}
                                styleType="text"
                                text={t('clear')}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignCenter)}>
                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Требуется доставка
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Field
                                    <FormDataType[FieldName.isNeedDelivery]>
                                    name={FieldName.isNeedDelivery}
                                    render={({ input, meta }) => (
                                        <Switch
                                            classes={style.switch}
                                            name={input.name}
                                            checked={Boolean(input.value)}
                                            onChange={({ target }) => input.onChange(Number(target.checked))}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Куда доставляем
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Куда доставляем
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.deliveryAddress]>
                            name={FieldName.deliveryAddress}
                            render={({ input, meta }) => (
                                <CustomSelect.Select>
                                    <CustomSelect.Field classes={style.fieldSelect}>
                                        {/* */}
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {/* */}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Статус доставки
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Статус доставки
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.deliveryStatus]>
                            name={FieldName.deliveryStatus}
                            render={({ input, meta }) => (
                                <CustomSelect.Select>
                                    <CustomSelect.Field classes={style.fieldSelect}>
                                        {/* */}
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {/* */}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                            )}
                        />
                    </div>
                </Block>
                <Block classes={style.block}>
                    <div className={style.blockHeader}>
                        <div className={style.blockTitle}>
                            Оплата
                        </div>
                        <div>
                            <Button
                                classes={style.blockAction}
                                styleType="text"
                                text={t('clear')}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Статус оплаты
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Статус оплаты
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.status]>
                            name={FieldName.status}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Способ оплаты
                                </div>
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {/* TODO */}
                                            Способ оплаты
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            <FormDataType[FieldName.payment]>
                            name={FieldName.payment}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                </Block>
            </CardContainer>
            <Button
                classes={style.control}
                size="size44"
                type="submit"
                text={t('apply')}
            />
        </form>
    )
}

export default CompanyOrdersFilterForm
