import React from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, Textarea } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderCommentForm.module.css'

export enum FieldName {
    comment = 'comment',
}

export type FormDataType = {
    [FieldName.comment]: string
}

export type OrderDeliveryDetailsFormPropType = {
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.comment]: '',
}

const OrderCommentForm: React.FC<OrderDeliveryDetailsFormPropType> = ({
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { hasValidationErrors } = useFormState<FormDataType>()

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <Field
                    <FormDataType[FieldName.comment]>
                    name={FieldName.comment}
                    render={({ input, meta }) => (
                        <Textarea
                            classes={style.fieldTextarea}
                            {...input}
                        />
                    )}
                />
            </div>
            <div className={style.controls}>
                <Button
                    classes={style.control}
                    styleType="bordered"
                    size="size44"
                    type="submit"
                    text={t('save')}
                    disabled={hasValidationErrors}
                />
            </div>
        </form>
    )
}

export default OrderCommentForm
