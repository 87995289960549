import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { Button, CustomSelect, Link } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './WarehouseSuppliesFilterForm.module.css'

// FIXME on ready backend
export enum FieldName {
    store = 'store',
    goods = 'goods',
}

export type FormDataType = {
    [FieldName.store]: number
    [FieldName.goods]: number
}

export type WarehouseFilterFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    isSubmitting: boolean
    stores?: { id: number, name: string }[] // FIXME on ready api request
    goods?: { id: number, name: string }[] // FIXME on ready api request
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.store]: 0,
    [FieldName.goods]: 0,
}

const WarehouseSuppliesFilterForm: React.FC<WarehouseFilterFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    isSubmitting,
    stores,
    goods,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const storesData: Record<number, string> = useMemo(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [stores])

    const goodsData: Record<number, string> = useMemo(() => {
        return goods
            ? goods.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [goods])

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={classesBody}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('stores')}
                    </div>
                    <Field
                        <FormDataType[FieldName.store]>
                        name={FieldName.store}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.fieldSelect}>
                                    {input.value ? storesData[input.value] : t('all')}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {stores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {/* TODO */}
                        Продукт
                    </div>
                    <Field
                        <FormDataType[FieldName.goods]>
                        name={FieldName.goods}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.fieldSelect}>
                                    {input.value ? goodsData[input.value] : t('all')}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {stores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.group}>
                        <div className={styleForm.fieldHead}>
                            {/* TODO */}
                            <Link className={style.actionAdd} url={APP_URL.suppliesAdd}>
                                Добавить поставку
                            </Link>
                        </div>
                        <div className={style.iconAdd} />
                    </div>
                </div>
            </div>
            <div className={classesFooter}>
                <Button
                    classes={style.control}
                    size="size44"
                    type="submit"
                    text={t('refresh')}
                />
            </div>
        </form>
    )
}

export default WarehouseSuppliesFilterForm
