export enum AppSettings {
    // reportsNew = 'reportsNew',
    feedPostTile = 'feedPostTile',
    shopCatalogUserView = 'shopCatalogUserView',
}

export enum AppScheme {
    pv = 'pv',
    cc = 'cc',
}

export enum AppTitle {
    pv = 'Project V',
    cc = 'Coffeecell'
}

export enum AppMode {
    user = 'user',
    business = 'business',
}

export enum AppMenuKey {
    feed = 1,
    friends = 2,
    market = 3,
    reports = 4,
    wallet = 5,
    calendar = 6,
    rank = 8,
    rating = 9,
    stores = 10,
    company = 11,
    kicks = 12,
    messenger = 13,
    clients = 14,
    support = 15,
    companyOrders = 16,
    warehouse = 17,
}

export enum DatePeriod {
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
    all = 'all',
    custom = 'custom',
}

export enum CompanyRoleId {
    admin = 1,
    cashier = 2,
    editor = 3,
    awaiting = 4,
}

export enum QueryCacheKeys {
    postsUser = 'posts-user',
    postsCommunity = 'posts-community',
    post = 'post',
    calendar = 'calendar',
    contacts = 'contacts',
    communities = 'communities',
    cities = 'cities',
    currencies = 'currencies',
    blocks = 'blocks',
    subscription = 'subscription',
    conversationList = 'conversation-list',
    conversationCountsNew = 'conversation-counts-new',
    counterHistory = 'counter-history',
    channel = 'channel',
    channelMembers = 'channel-members',
    chatHistoryChat = 'chat-history-chat',
    chatHistoryChannel = 'chat-history-channel',
    chatUserBelongsConversations = 'chat-user-belongs-conversations',
    chatUserBelongsChannels = 'chat-user-belongs-channels',
    chatUserBelongsMessages = 'chat-user-belongs-messages',
    profile = 'profile',
    profileFriends = 'profile-friends',
    friends = 'friends',
    friendsStore = 'friends-store',
    friendsRequest = 'friends-request',
    friendsSearch = 'friends-search',
    friendsChatSearch = 'friends-chat-search',
    filterAccounts = 'filter-accounts',
    usersData = 'users-data',
    marketCategories = 'marketCategories',
    subscribe = 'subscribe',
    unsubscribeStore = 'unsubscribe-store',
    unsubscribeCompany = 'unsubscribe-company',
    shops = 'shops',
    shopsStatic = 'shops-static',
    personalStores = 'personal-stores',
    personalStore = 'personal-store',
    storesProfiles = 'stores-profiles',
    storeProfile = 'store-profile',
    storeSettings = 'store-settings',
    storeLoyaltySettings = 'store-storeLoyalty-settings',
    storeRootCatalog = 'store-root-catalog',
    storeCatalog = 'store-catalog',
    storeShowcase = 'store-showcase',
    storeGoods = 'store-goods',
    storeGoodsAvailableStatus = 'store-goods-available-status',
    storeGoodsTypes = 'store-goods-types',
    storeRecommendedGoods = 'store-recommended-goods',
    storePopularGoods = 'store-popular-goods',
    clients = 'clients',
    ratingTypeSum = 'rating-type-sum',
    ratingList = 'rating-list',
    reports = 'reports',
    report = 'report',
    ticket = 'ticket',
    tickets = 'tickets',
    mlmReports = 'mlm-reports',
    mlmReportsUsers = 'mlm-reports-users',
    mlmReportsRanksCurrent = 'mlm-reports-ranks-current',
    mlmReportsRanksCurrentUsers = 'mlm-reports-ranks-current-users',
    mlmReportsRanksMax = 'mlm-reports-ranks-max',
    mlmReportsRanksMaxUsers = 'mlm-reports-ranks-max-users',
    mlmReportsRanksNew = 'mlm-reports-ranks-new',
    mlmReportsRanksNewUsers = 'mlm-reports-ranks-new-users',
    mlmReportsClientsGraph = 'mlm-reports-clients-graph',
    mlmReportsIncomeGraph = 'mlm-reports-income-graph',
    mlmReportsLoginGraph = 'mlm-reports-login-graph',
    mlmReportsActivePartnersGraph = 'mlm-reports-active-partners-graph',
    mlmReportsTurnoverGraph = 'mlm-reports-turnover-graph',
    mlmReportsTurnoverProducts = 'mlm-reports-turnover-products',
    mlmReportsIncomeList = 'mlm-reports-income-list',
    mlmRankUsers = 'mlm-rank-users',
    mlmRankStats = 'mlm-rank-stats',
    mlmPvBusinessPack = 'mlm-pv-business-pack',
}

export enum PhoneDataFields {
    countryId = 'id',
    countryCode = 'code',
    phone = 'value',
}

export enum SmsCodeField {
    smsCode = 'smsCode'
}

export enum CardSize {
    fullWidth = 'fullWidth',
    halfWidth = 'halfWidth',
    thirdWidth = 'thirdWidth',
    fourthWidth = 'fourthWidth',
}

export enum ChartPeriod {
    all,
    year,
    month,
    week
}

export enum ChartPeriodTranslate {
    ui_charts_period_all,
    ui_charts_period_year,
    ui_charts_period_month,
    ui_charts_period_week,
}

export enum MessengerMessageType {
    chatStatus = 'CHAT_STATUS',
    status = 'MESSAGE_STATUS',
    massStatus = 'MESSAGES_STATUS',
    message = 'NEW_MESSAGE',
    forward = 'MESSAGE_FORWARD',
    reply = 'MESSAGE_REPLY',
    error = 'ERROR',
    chat = 'P2P',
    channel = 'P2C',
}

export enum MessengerMessageStatus {
    delivered = 'DELIVERED',
    deleted = 'DELETED',
    read = 'READ',
    unread = 'UNREAD',
}

export enum FriendFilterSortBy {
    posts_count = 'posts_count',
    rating = 'rating',
}

export enum SortKey {
    ASC,
    DESC,
}
