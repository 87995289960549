import React from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { TableList, NoDataInfo, Link } from 'components'
import { dateTimeFormat, numberFormat } from 'utils/helpers'
import style from './CompanyOrdersList.module.css'

// FIXME
type TData = {
    id: number
    date: Date
    name: string
    sum: number
    status: string
    code: number
    datePayment: string
    dateOrder: string
    bonus: number
    store: number
    comment: string
}

type TColumn = {
    id: number
    key: keyof TData
    name: string
    checked: boolean
}

type CompanyOrdersListPropType = {
    columns: TColumn[]
    data?: TData[]
}

const CompanyOrdersList: React.FC<CompanyOrdersListPropType> = ({ columns, data }) => {
    const { t, i18n } = useTranslation()

    const getFormattedValue = (key: keyof TData, values: TData) => {
        if (key === 'id') {
            return (
                <Link
                    className={style.link}
                    url={generatePath(APP_URL.companyOrder, { id: values[key] })}
                >
                    {values[key]}
                </Link>
            )
        }
        if (key === 'name') {
            return (
                <Link
                    className={cn(style.link, style.link_blue)}
                    // TODO
                    url=""
                >
                    {values[key]}
                </Link>
            )
        }
        if (key === 'sum') {
            // TODO priceFormat
            return `${numberFormat(values[key])} ₽`
        }
        if (key === 'datePayment') {
            return dateTimeFormat(values[key], i18n.language, {
                dateStyle: 'short',
                timeStyle: 'medium',
            })
        }

        return values[key]
    }

    return (
        data ? (
            <TableList classes={style.list}>
                <TableList.Header classes={style.header}>
                    {columns.map((item) => (
                        <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                            <div className={style.colInner}>
                                <div className={style.headerTitle}>
                                    {item.name}
                                </div>
                                {item.key !== 'comment' && (
                                    <TableList.Sort
                                        classes={style.headerSort}
                                        sort="ASC"
                                        onClick={() => {}}
                                    />
                                )}
                            </div>
                        </TableList.Col>
                    ))}
                </TableList.Header>
                <TableList.Body>
                    {!data.length && (
                        <NoDataInfo text={t('no_data')} />
                    )}
                    {data.map((item) => (
                        <TableList.Row classes={style.row} key={item.id}>
                            {columns.map((itemColumn) => (
                                <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                    <div className={style.colTitle}>
                                        {itemColumn.name}
                                    </div>
                                    <div className={style.colInner}>
                                        {getFormattedValue(itemColumn.key, item)}
                                    </div>
                                </TableList.Col>
                            ))}
                        </TableList.Row>
                    ))}
                </TableList.Body>
            </TableList>
        ) : null
    )
}

export default CompanyOrdersList
