import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import cn from 'classnames'

import { INPUT_DEBOUNCE_TIME } from 'config/app'
import { Input, Spinner, SvgResource } from 'components'
import { useOutsideClick } from 'hooks'
import { strPartsHighlight } from 'utils/helpers'
import { SuggestFieldItemType } from './components/SuggestField/SuggestField'
import { SuggestField } from './components'
import style from './Suggest.module.css'

export type SuggestItemPropType = {
    id: number
    value: string
}

export type SuggestPropType = {
    classes?: string
    classesField?: string
    items: SuggestItemPropType[]
    placeholder?: string
    disabled?: boolean
    isShowArrow?: boolean
    onSearch: (value: string) => void
    onSelect: (value: SuggestItemPropType) => void
}

const Suggest: React.FC<SuggestPropType> = ({
    classes,
    classesField,
    items,
    placeholder,
    disabled,
    isShowArrow,
    onSearch,
    onSelect,
}) => {
    const { t } = useTranslation()
    const debounce = useDebouncedCallback(changeCallback, INPUT_DEBOUNCE_TIME)
    const wrapperRef = useRef(null)

    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [isShowOptions, setIsShowOptions] = useState(false)

    const suggestions = useMemo(() => getSuggestions(items), [items, query])

    const handlerFocus = () => {
        if (suggestions?.length) {
            setIsShowOptions(true)
        }
    }

    const handlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget

        setIsShowOptions(!!value)
        setSelected('')
        setQuery(value)

        debounce()
    }

    const handlerSelect = (id: number) => {
        const selectedItem = items.find((item) => item.id === id)

        setIsShowOptions(false)

        if (selectedItem) {
            setSelected(selectedItem.value)
            onSelect(selectedItem)
        }
    }

    function changeCallback() {
        setIsLoading(true)
        onSearch(query)
    }

    function getSuggestions(suggestionItems: SuggestItemPropType[] = []) {
        const suggestionsParts = suggestionItems.reduce((acc: SuggestFieldItemType[], item: SuggestItemPropType)
            :SuggestFieldItemType [] => {
            const parts = strPartsHighlight(item.value, query)
            return [...acc, { id: item.id, parts }]
        }, [])

        return suggestionsParts.filter((item) => item.parts.length > 1)
    }

    useOutsideClick(wrapperRef, () => {
        setIsShowOptions(false)
    })

    useEffect(() => {
        setIsLoading(false)
    }, [items])

    return (
        <div className={cn(style.suggest, classes)} ref={wrapperRef}>
            <div className={style.fieldWrap}>
                <Input
                    classes={cn(style.field, { [style.field_up]: isShowOptions }, classesField)}
                    placeholder={placeholder}
                    value={selected || query}
                    onFocus={handlerFocus}
                    onChange={handlerChange}
                />
                {isShowArrow && !disabled && (
                    <SvgResource
                        classes={cn(style.fieldArrow, { [style.fieldArrow_up]: isShowOptions }, style.iconArrow)}
                        resourceKey="dropdpwn_daw_svg"
                        isImgTag={false}
                        width={18}
                        height={16}
                    />
                )}
            </div>
            <div className={cn(style.options, { [style.options_active]: isShowOptions })}>
                {suggestions.length ? (
                    <>
                        {isLoading && (
                            <Spinner size="small" />
                        )}
                        <ul className={style.list}>
                            {suggestions.map((item) => (
                                <SuggestField
                                    suggest={item}
                                    key={item.id}
                                    onSelect={handlerSelect}
                                />
                            ))}
                        </ul>
                    </>
                ) : (
                    <div className={style.noData}>
                        {t('nothing_found')}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Suggest
