import React from 'react'
import cn from 'classnames'

import { Button } from 'components'
import style from './Tag.module.css'

type TagPropType = {
    classes?: string
    classesContent?: string
    classesCross?: string
    onDelete?: () => void
    onClick?: () => void
}

const Tag: React.FC<TagPropType> = ({
    children,
    classes,
    classesContent,
    classesCross,
    onDelete,
    onClick,
}) => {
    return (
        <div className={cn(style.tag, classes)}>
            {onClick ? (
                <Button
                    classes={cn(style.content, classesContent)}
                    styleType="transparent"
                    onClick={onClick}
                >
                    {children}
                </Button>
            ) : (
                <div className={cn(style.content, classesContent)}>
                    {children}
                </div>
            )}
            {onDelete && (
                <Button
                    classes={style.action}
                    styleType="transparent"
                    onClick={(e) => {
                        e.stopPropagation()
                        onDelete()
                    }}
                >
                    <span className={cn(style.cross, classesCross)} />
                </Button>
            )}
        </div>
    )
}

export default Tag
