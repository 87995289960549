import React from 'react'
import cn from 'classnames'

import { Button } from 'components'
import { OrderDeliveryTypeAction } from 'form-actions'
import style from './OrdersOrderDelivery.module.css'

type OrdersOrderDeliveryPropType = {
    classes?: string
    onClickDelivery: () => void
}

const OrdersOrderDelivery: React.FC<OrdersOrderDeliveryPropType> = ({ classes, onClickDelivery }) => {
    return (
        <div className={cn(style.wrap, classes)}>
            <OrderDeliveryTypeAction />

            <div className={style.actions}>
                <Button
                    classes={style.action}
                    styleType="text"
                    // TODO
                    text="Детали доставки"
                    onClick={onClickDelivery}
                />
            </div>
        </div>
    )
}

export default OrdersOrderDelivery
