import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TableList, NoDataInfo } from 'components'
// import { dateTimeFormat } from 'utils/helpers'
import style from './OrdersOrderHistoryList.module.css'

// FIXME
type TData = {
    id: number
    date: string
    action: string
}

type TColumn = {
    id: number
    key: keyof TData
    name: string
}

type OrdersOrderHistoryListPropType = {
    columns: TColumn[]
    data?: TData[]
}

const OrdersOrderHistoryList: React.FC<OrdersOrderHistoryListPropType> = ({ columns, data }) => {
    const { t } = useTranslation()

    const getFormattedValue = (key: keyof TData, values: TData) => {
        if (key === 'date') {
            // TODO dateTimeFormat
            return values[key]
        }

        return values[key]
    }

    return (
        data ? (
            <TableList classes={style.list}>
                <TableList.Header classes={style.header}>
                    {columns.map((item) => (
                        <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                            <div className={style.headerTitle}>
                                {item.name}
                            </div>
                        </TableList.Col>
                    ))}
                </TableList.Header>
                <TableList.Body>
                    {!data.length && (
                        <NoDataInfo text={t('no_data')} />
                    )}
                    {data.map((item) => (
                        <TableList.Row classes={style.row} key={item.id}>
                            {columns.map((itemColumn) => (
                                <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                    <div className={style.colTitle}>
                                        {itemColumn.name}
                                    </div>
                                    <div className={style.colInner}>
                                        {getFormattedValue(itemColumn.key, item)}
                                    </div>
                                </TableList.Col>
                            ))}
                        </TableList.Row>
                    ))}
                </TableList.Body>
            </TableList>
        ) : null
    )
}

export default OrdersOrderHistoryList
