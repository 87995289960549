import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TableList, NoDataInfo, Link } from 'components'
import { numberFormat } from 'utils/helpers'
import style from './OrdersOrderContentsList.module.css'

// FIXME
type TData = {
    id: number
    name: string
    count: number
    price: number
    paymentForCv: number
    paymentCv: number
    sum: number
    currency: string
}

type TColumn = {
    id: number
    key: keyof TData
    name: string
}

type OrdersOrderContentsListPropType = {
    columns: TColumn[]
    data?: TData[]
}

const OrdersOrderContentsList: React.FC<OrdersOrderContentsListPropType> = ({ columns, data }) => {
    const { t } = useTranslation()

    const getFormattedValue = (key: keyof TData, values: TData) => {
        if (key === 'name') {
            return (
                <Link
                    className={cn(style.link, style.link_blue)}
                    // TODO
                    url=""
                >
                    {values[key]}
                </Link>
            )
        }
        if (key === 'price' || key === 'paymentForCv' || key === 'paymentCv' || key === 'sum') {
            // TODO priceFormat
            return `${numberFormat(values[key])} ${values.currency}`
        }

        return values[key]
    }

    return (
        data ? (
            <TableList classes={style.list}>
                <TableList.Header classes={style.header}>
                    {columns.map((item) => (
                        <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                            <div className={style.headerTitle}>
                                {item.name}
                            </div>
                        </TableList.Col>
                    ))}
                </TableList.Header>
                <TableList.Body>
                    {!data.length && (
                        <NoDataInfo text={t('no_data')} />
                    )}
                    {data.map((item) => (
                        <TableList.Row classes={style.row} key={item.id}>
                            {columns.map((itemColumn) => (
                                <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                    <div className={style.colTitle}>
                                        {itemColumn.name}
                                    </div>
                                    <div className={style.colInner}>
                                        {getFormattedValue(itemColumn.key, item)}
                                    </div>
                                </TableList.Col>
                            ))}
                        </TableList.Row>
                    ))}
                </TableList.Body>
                <TableList.Footer classes={style.footer}>
                    <TableList.Row classes={style.row}>
                        {columns.map((itemColumn) => (
                            <TableList.Col
                                classes={cn(
                                    style.col,
                                    style.col_footer,
                                    { [style.col_mobile_hidden]: itemColumn.key !== 'name' && itemColumn.key !== 'sum' },
                                )}
                                key={itemColumn.key}
                            >
                                {/* TODO */}
                                {itemColumn.key === 'name' && 'Итого'}
                                {itemColumn.key === 'sum' && (
                                    <>
                                        {numberFormat(22000)}
                                        {' '}
                                        €
                                    </>
                                )}
                            </TableList.Col>
                        ))}
                    </TableList.Row>
                </TableList.Footer>
            </TableList>
        ) : null
    )
}

export default OrdersOrderContentsList
