import React, {
    useEffect,
    useState,
    useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { BREAKPOINTS } from 'config/app'
import { ContentContainer, Block } from 'layout'
import {
    PageHeader,
    Button,
    Checkbox,
    SvgResource,
    Popover,
    Modal,
} from 'components'
import { CompanyOrdersPresetsAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { CompanyOrdersFilter, CompanyOrdersList, CompanyOrdersPresets } from '../components'
import style from './OrdersOrders.module.css'

// FIXME
type TData = {
    id: number
    date: Date
    name: string
    sum: number
    status: string
    code: number
    datePayment: string
    dateOrder: string
    bonus: number
    store: number
    comment: string
}

type TColumn = {
    id: number
    key: keyof TData
    name: string
    checked: boolean
}

const LIST_COLUMNS: TColumn[] = [
    {
        id: 0,
        key: 'id',
        name: 'ID заказа',
        checked: true,
    },
    {
        id: 1,
        key: 'name',
        name: 'ФИО',
        checked: true,
    },
    {
        id: 2,
        key: 'code',
        name: 'Промокод получателя',
        checked: false,
    },
    {
        id: 3,
        key: 'sum',
        name: 'Сумма',
        checked: true,
    },
    {
        id: 4,
        key: 'store',
        name: 'Магазин',
        checked: false,
    },
    {
        id: 5,
        key: 'datePayment',
        name: 'Дата и время оплаты',
        checked: true,
    },
    {
        id: 6,
        key: 'dateOrder',
        name: 'Дата создания заказа',
        checked: false,
    },
    {
        id: 7,
        key: 'bonus',
        name: 'Бонусы за заказ',
        checked: false,
    },
    {
        id: 8,
        key: 'status',
        name: 'Статус оплаты',
        checked: true,
    },
    {
        id: 9,
        key: 'comment',
        name: 'Комментарий',
        checked: false,
    },
]

const randomInteger = (min: number, max: number) => {
    const rand = min + Math.random() * (max + 1 - min)
    return Math.floor(rand)
}

const OrdersOrders: React.FC = () => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()
    const ordersRef = useRef<HTMLDivElement>(null)

    const [isOpenFilter, setIsOpenFilter] = useState(true)
    const [isOpenOrders, setIsOpenOrders] = useState(false)
    const [isOpenListSettings, setIsOpenListSettings] = useState(false)
    const [isOpenModalPresets, setIsOpenModalPresets] = useState(false)
    const [dataOrders, setDataOrders] = useState<TData[]>()
    // eslint-disable-next-line
    const [datePresets, setDataPresets] = useState([
        { id: 1, name: 'Курица' },
        { id: 2, name: 'Овощи' },
        { id: 3, name: 'Мясо' },
        { id: 4, name: 'preset 4' },
        { id: 5, name: 'preset 5' },
        { id: 6, name: 'preset 6' },
        { id: 7, name: 'preset 6' },
        { id: 8, name: 'preset 6' },
    ])

    const [listActiveColumns] = useState<TColumn[]>(LIST_COLUMNS.reduce((acc, item) => {
        return item.checked ? [...acc, item] : acc
    }, [] as TColumn[]))

    const handlerSuccessOrdersFilter = () => { // values: OrdersFilterFormDataType
        const today = new Date()
        // const date = new Date(values[FieldName.date])
        // const dateTo = new Date(values[FieldName.dateTo])
        // const days = Math.ceil(Math.abs(dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24))
        const dateFrom = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15)
        const days = 15
        const status = ['Оплачен виртуально', 'Произведен частичный возврат', 'Операция отменена банком']

        // FIXME fake data
        setDataOrders(Array(days).fill({}).reduce((acc, item, index) => {
            const date = new Date(
                dateFrom.getFullYear(),
                dateFrom.getMonth(),
                dateFrom.getDate() + index,
                randomInteger(8, 20),
                randomInteger(0, 59),
                randomInteger(0, 59),
            )

            return date <= today
                ? [
                    ...acc,
                    {
                        id: randomInteger(1310000, 1320000),
                        datePayment: date,
                        name: 'Name Surname',
                        sum: randomInteger(30000, 150000),
                        status: status[randomInteger(0, status.length - 1)],
                    },
                ]
                : acc
        }, []))
    }

    useEffect(() => {
        if (dataOrders) {
            setIsOpenOrders(true)
            setTimeout(() => {
                if (ordersRef.current) {
                    ordersRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
            }, 300)
        }
    }, [dataOrders])

    return (
        <>
            <ContentContainer classes={style.content} size="three-quarters">
                <PageHeader title={t('orders')} />

                <Block classes={cn(style.block, style.block_gray)}>
                    <Block.Header classes={style.blockHeader}>
                        <div className={style.blockTitle}>
                            {/* TODO */}
                            Фильтр
                        </div>
                        <div className={style.blockActions}>
                            <Button
                                textUpper={false}
                                classes={cn(style.blockAction, style.blockAction_violet)}
                                styleType="small"
                                size="size30"
                                text={t('presets')}
                                onClick={() => setIsOpenModalPresets(true)}
                            />
                            <Button
                                textUpper={false}
                                classes={style.blockAction}
                                styleType="small"
                                size="size30"
                                text={t('clear')}
                                onClick={() => {}}
                            />
                            <Button
                                textUpper={false}
                                classes={style.blockAction}
                                styleType="small"
                                size="size30"
                                text={isOpenFilter ? t('hide') : t('show')}
                                onClick={() => setIsOpenFilter((prevState) => !prevState)}
                            />
                        </div>
                    </Block.Header>
                    <Block.BodyCollapse
                        isOpen={isOpenFilter}
                        classesContent={style.blockBody}
                    >
                        <CompanyOrdersFilter onSuccess={handlerSuccessOrdersFilter} />
                    </Block.BodyCollapse>
                </Block>
                <div className={style.divider} ref={ordersRef} />
                <Block classes={style.block}>
                    <Block.Header classes={style.blockHeader}>
                        <Block.HeaderInner classes={style.blockHeaderInner}>
                            <div className={style.blockTitle}>
                                {/* TODO */}
                                Список заказов
                            </div>
                            <div className={style.blockActions}>
                                <Popover
                                    classes={cn(style.blockAction, style.blockActionSettings)}
                                    classesBody={style.listSettings}
                                    isOpen={isOpenListSettings}
                                    isShowArrow={false}
                                    isAutoClose={false}
                                    isPopup={windowWidth < BREAKPOINTS.tabletLandscape}
                                    trigger={(
                                        <SvgResource
                                            resourceKey="ic_list_svg"
                                            width={18}
                                            height={14}
                                        />
                                    )}
                                    position="bottom"
                                    side="left"
                                    isDisabled={!isOpenOrders}
                                    onOpen={() => setIsOpenListSettings(true)}
                                    onClose={() => setIsOpenListSettings(false)}
                                >
                                    {LIST_COLUMNS.map((item) => (
                                        <div className={style.ordersListSettingsItem} key={item.id}>
                                            <Checkbox
                                                styleType="small"
                                                name=""
                                                text={item.name}
                                                checked={item.checked}
                                                onChange={() => {}}
                                            />
                                        </div>
                                    ))}
                                </Popover>
                            </div>
                        </Block.HeaderInner>
                    </Block.Header>
                    <Block.BodyCollapse
                        isOpen={isOpenOrders}
                        classesContent={style.blockBody}
                    >
                        <CompanyOrdersList
                            columns={listActiveColumns}
                            data={dataOrders}
                        />
                    </Block.BodyCollapse>
                </Block>
            </ContentContainer>

            <Modal
                isOpen={isOpenModalPresets}
                classes={style.modalPresets}
                size="smallCenter"
                onClose={() => setIsOpenModalPresets(false)}
            >
                <Modal.Header
                    classes={style.modalPresetsHeader}
                    classesTitle={style.modalPresetsTitle}
                    title={t('presets')}
                    titlePos="left"
                />
                <Modal.Body classes={style.modalPresetsBody}>
                    <CompanyOrdersPresets
                        classes={style.presets}
                        data={datePresets}
                        onDelete={() => {}}
                        onSelect={() => {}}
                    />
                    <CompanyOrdersPresetsAction />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrdersOrders
